import ReactGA from "react-ga";

const useAnalyticsEventTracker = (params: any) => {
  const eventTracker = (params: any) => {
    ReactGA.event(params);
  }
  return eventTracker(params);
}

export default useAnalyticsEventTracker;
