import React, {FC, memo} from 'react';
import s from './Card.module.scss';
import classNames from "classnames";
import {Button} from "../../bll/button/Button";
import imageDiscount from './../../../assets/images/discount.png';
import useAnalyticsEventTracker from './../../../utils/helpers/functions/useAnalyticsEventTracker';

type CardType = {
  className?: string
  image: string
  title: string
  text: string
  href: string
  mainProductTitle: string | ''
};

export const Card: FC<CardType> = memo(({
  className,
  image,
  title,
  text,
  href,
  mainProductTitle
}) => {
  const statisticHref = href + '?utm_campaign=2022Q4-rc-ru-other-other-recommendationAlternativeToImportedProducts&utm_source=other&utm_medium=brand-short-x&utm_content=button-x'

  const gaEventTracker = (title: any) => {
    useAnalyticsEventTracker({
      category: 'alternativeProduct',
      action: 'click',
      label: mainProductTitle + '|' + title,
    })
  }

  return (
    <article className={classNames(s.card, className)}>
      <div className={s.wrap}>
        <img className={s.image} src={image} alt={'Product'}/>
        <img className={s.discount} src={imageDiscount} alt="Discount"/>
      </div>
      <h3 className={classNames(s.title, s.h2)}>{title}</h3>
      <p className={classNames(s.text, s.p3)}>{text}</p>
      <Button className={s.button}
              buttonType={'link'}
              onClickButton={() => gaEventTracker(title)}
              href={statisticHref}>Узнать больше</Button>
    </article>
  );
})
