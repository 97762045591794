import {dataType} from "./data-cat";

export const dataDog: Array<dataType> = [
  {
    id: '1004',
    linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-8+-1004',
    text: 'корм сухой для собак в возрасте 8 лет и старше',
    nameProductRu: 'Икс-смол эдалт 8+',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263236-master.jpg',
    nameProductEa: 'X-Small Mature+8',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 1,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      }
    ]
  },
  {
    id: '1230',
    linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-light-weight-care-1230',
    text: 'Для собак, склонных к набору веса',
    nameProductRu: 'Икс-Смол Лайт Вейт Кэа',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-420911-master.jpg',
    nameProductEa: 'X-Small Light Weight Care',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      },
      {
        id: '1261',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-urinary-care-1261',
        nameProductRu: 'Мини Уринари Кэа: корм для собак с чувствительной мочевыделительной системой.',
        text: 'сухой корм для сабок чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264111-master.jpg',
        nameProductEa: 'Mini Urinary Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 1,
      }
    ]
  },
  {
    id: '1019',
    linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-sterilised-1019',
    text: 'корм сухой для взрослых стерилизованных собак',
    nameProductRu: 'Икс-смол стерилайзд',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263307-master.jpg',
    nameProductEa: 'X-Small Sterilised',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      },
      {
        id: '1261',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-urinary-care-1261',
        nameProductRu: 'Мини Уринари Кэа: корм для собак с чувствительной мочевыделительной системой.',
        text: 'сухой корм для сабок чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264111-master.jpg',
        nameProductEa: 'Mini Urinary Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 1,
      }
    ]
  },
  {
    id: '3002',
    linkProduct: 'https://www.royalcanin.com/ru/shop/mini-adult-8+-3002',
    text: 'сухой корм для собак в возрасте 8 лет и старше',
    nameProductRu: 'Мини эдалт 8+',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137078-master.jpg',
    nameProductEa: 'Mini Adult+8',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
    ]
  },
  {
    id: '1006',
    linkProduct: 'https://www.royalcanin.com/ru/shop/mini-exigent-1006',
    text: 'сухой корм для собак мелких размеров, привередливых в питании',
    nameProductRu: 'Мини экзиджент',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-191779-master.jpg',
    nameProductEa: 'Mini Exigent',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 1,
      }
    ]
  },
  {
    id: '3018',
    linkProduct: 'https://www.royalcanin.com/ru/shop/mini-light-weight-care-3018',
    text: 'корм сухой для собак, склонных к набору веса',
    nameProductRu: 'Мини Лайт Вэйт Кэа',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-274138-master.jpg',
    nameProductEa: 'Mini Light Weight Care',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '1261',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-urinary-care-1261',
        nameProductRu: 'Мини Уринари Кэа: корм для собак с чувствительной мочевыделительной системой.',
        text: 'сухой корм для сабок чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264111-master.jpg',
        nameProductEa: 'Mini Urinary Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 1,
      }
    ]
  },
  {
    id: '1224',
    linkProduct: 'https://www.royalcanin.com/ru/shop/mini-relax-care-1224',
    text: 'корм сухой для собак в период смены обстановки.',
    nameProductRu: 'Мини релакс кэа',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264343-master.jpg',
    nameProductEa: 'Mini Relax Care',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 2,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      }
    ]
  },
  {
    id: '3021',
    linkProduct: 'https://www.royalcanin.com/ru/shop/medium-light-weight-care-3021',
    text: 'корм сухой для собак, склонных к набору веса.',
    nameProductRu: 'Медиум Лайт Вэйт Кэа',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-274147-master.jpg',
    nameProductEa: 'Medium Light Weight Care',
    recommendation: [
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      },
      {
        id: '3004',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-adult-3004',
        nameProductRu: 'Медиум эдалт',
        text: 'сухой корм для собак в возрасте 12 месяцев и старше.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137073-master.jpg',
        nameProductEa: 'Medium Adult',
        priority: 1,
      },
      {
        id: '3007',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-adult-3007',
        nameProductRu: 'Макси Эдалт: сухой корм для собак в возрасте с 15 месяцев до 5 лет',
        text: 'корм для собак с чевствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137067-master.jpg',
        nameProductEa: 'Maxi Adult',
        priority: 2,
      }
    ]
  },
  {
    id: '2446',
    linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-light-weight-care-2446',
    text: 'корм сухой для собак, склонных к набору веса.',
    nameProductRu: 'Макси Лайт Вэйт Кэа',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263948-master.jpg',
    nameProductEa: 'Maxi Light Weight Care',
    recommendation: [
      {
        id: '3007',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-adult-3007',
        nameProductRu: 'Макси Эдалт: сухой корм для собак в возрасте с 15 месяцев до 5 лет',
        text: 'корм для собак с чевствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137067-master.jpg',
        nameProductEa: 'Maxi Adult',
        priority: 1,
      },
      {
        id: '3009',
        linkProduct: 'https://www.royalcanin.com/ru/shop/giant-adult-3009',
        nameProductRu: 'Джайнт Эдалт: сухой корм для собак в возрасте 18/24 месяцев и старше.',
        text: 'сухой корм для щенков в возрасте до 8 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137062-master.jpg',
        nameProductEa: 'GIANT Adult',
        priority: 2,
      }
    ]
  },
  {
    id: '4134',
    linkProduct: 'https://www.royalcanin.com/ru/shop/bichon-frise-adult-4134',
    text: 'для взрослых собак в возрасте от 10 месяцев и старше.',
    nameProductRu: 'Бишон Фризе',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_660095_master.jpg',
    nameProductEa: 'Bichon Frise',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 1,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      }
    ]
  },
  {
    id: '3995',
    linkProduct: 'https://www.royalcanin.com/ru/shop/maltese-adult-3995',
    text: 'для взрослых собак в возрасте от 10 месяцев и старше',
    nameProductRu: 'Мальтийская Болонка',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_661450_master.jpg',
    nameProductEa: 'Malteze adult',
    recommendation: [
      {
        id: '1003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-adult-1003',
        nameProductRu: 'Икс-смол эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137082-master.jpg',
        nameProductEa: 'X-Small Adult',
        priority: 1,
      },
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 2,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      }
    ]
  },
  {
    id: '4130',
    linkProduct: 'https://www.royalcanin.com/ru/shop/pug-puppy-4130',
    text: 'корм сухой для щенков породы мопс до 10-месячного возраста',
    nameProductRu: 'Мопс Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-262736-master.jpg',
    nameProductEa: 'Pug Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '3990',
    linkProduct: 'https://www.royalcanin.com/ru/shop/french-bulldog-puppy-3990',
    text: 'сухой корм для щенков в возрасте до 12 месяцев',
    nameProductRu: 'Французский бульдог Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/X2mmrWsBG95Xk-RBjfvI/v270/pu-french-bulldog-packshot-bhn18',
    nameProductEa: 'French bulldog puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '2437',
    linkProduct: 'https://www.royalcanin.com/ru/shop/dachshund-puppy-2437',
    text: 'сухой корм для щенков породы Такса до 10-месячного возраста',
    nameProductRu: 'Такса Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-262695-master.jpg',
    nameProductEa: 'Dаchshund puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '2592',
    linkProduct: 'https://www.royalcanin.com/ru/shop/great-dane-adult-2592',
    text: 'для взрослых собак с 24-месячного возраста',
    nameProductRu: 'Немецкий Дог',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_662261_master.jpg',
    nameProductEa: 'Great Dane adult',
    recommendation: [
      {
        id: '3007',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-adult-3007',
        nameProductRu: 'Макси Эдалт: сухой корм для собак в возрасте с 15 месяцев до 5 лет',
        text: 'корм для собак с чевствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137067-master.jpg',
        nameProductEa: 'Maxi Adult',
        priority: 2,
      },
      {
        id: '3009',
        linkProduct: 'https://www.royalcanin.com/ru/shop/giant-adult-3009',
        nameProductRu: 'Джайнт Эдалт: сухой корм для собак в возрасте 18/24 месяцев и старше.',
        text: 'сухой корм для щенков в возрасте до 8 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137062-master.jpg',
        nameProductEa: 'GIANT Adult',
        priority: 1,
      }
    ]
  },
  {
    id: '2439',
    linkProduct: 'https://www.royalcanin.com/ru/shop/корм-для-щенков-в-возрасте-до-10-месяцев-для-породы-ши-тцу-2439',
    text: 'сухой корм для щенков в возрасте до 10 месяцев.',
    nameProductRu: 'Ши-тцу Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-274105-master.jpg',
    nameProductEa: 'Shih Tzu Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '2200',
    linkProduct: 'https://www.royalcanin.com/ru/shop/shih-tzu-2200',
    text: 'сухой корм с 10-месячного возраста.',
    nameProductRu: 'Ши-Тцу',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137052-master.jpg',
    nameProductEa: 'Shih Tzu Adult ',
    recommendation: [
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2441',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dermacomfort-2441',
        nameProductRu: 'Мини дермакомфорт',
        text: 'cухой корм для собак - рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263924-master.jpg',
        nameProductEa: 'Mini Dermacomfort',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      }
    ]
  },
  {
    id: '2101',
    linkProduct: 'https://www.royalcanin.com/ru/shop/jack-russell-terrier-puppy-2101',
    text: 'сухой корм для щенков породы джек-рассел-терьер в возрасте до 10 месяцев.',
    nameProductRu: 'Джек Рассел Терьер Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261112-master.jpg',
    nameProductEa: 'Jack Russel Terrier Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '2100',
    linkProduct: 'https://www.royalcanin.com/ru/shop/jack-russell-adult-2100',
    text: 'сухой корм для взрослых собак с 10-месячного возраста:',
    nameProductRu: 'Джек Рассел Терьер',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137051-master.jpg',
    nameProductEa: 'Jack Russel Terrier Adult ',
    recommendation: [
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2441',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dermacomfort-2441',
        nameProductRu: 'Мини дермакомфорт',
        text: 'cухой корм для собак - рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263924-master.jpg',
        nameProductEa: 'Mini Dermacomfort',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      }
    ]
  },
  {
    id: '3981',
    linkProduct: 'https://www.royalcanin.com/ru/shop/west-highland-white-terrier-3981',
    text: 'сухой корм для взрослых собакс 10-месячного возраста',
    nameProductRu: 'Вест-хайленд-уайт-терьер',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-136932-master.jpg',
    nameProductEa: 'Westie Adult ',
    recommendation: [
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2441',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dermacomfort-2441',
        nameProductRu: 'Мини дермакомфорт',
        text: 'cухой корм для собак - рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263924-master.jpg',
        nameProductEa: 'Mini Dermacomfort',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      }
    ]
  },
  {
    id: '2106',
    linkProduct: 'https://www.royalcanin.com/ru/shop/beagle-adult-2106',
    text: 'сухой корм для взрослых собак старше 12 месяцев.',
    nameProductRu: 'Бигль',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-191873-master.jpg',
    nameProductEa: 'Beagle Adult ',
    recommendation: [
      {
        id: '3004',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-adult-3004',
        nameProductRu: 'Медиум эдалт',
        text: 'сухой корм для собак в возрасте 12 месяцев и старше.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137073-master.jpg',
        nameProductEa: 'Medium Adult',
        priority: 1,
      },
      {
        id: '2442',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-dermacomfort-2442',
        nameProductRu: 'Медиум дерма комфорт',
        text: 'сухой корм рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью кожи',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-191892-master.jpg',
        nameProductEa: 'Medium Dermacomfort',
        priority: 2,
      },
      {
        id: '3016',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-digestive-care-3016',
        nameProductRu: 'Медиум дайджестив кэа',
        text: 'сухой корм для собак с чувствительным пищеварением.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-260679-master.jpg',
        nameProductEa: 'Medium Digestive Care',
        priority: 2,
      }
    ]
  },
  {
    id: '2597',
    linkProduct: 'https://www.royalcanin.com/ru/shop/dalmatian-puppy-2597',
    text: 'корм сухой для щенков в возрасте до 15 месяцев.',
    nameProductRu: 'Далматин Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-284228-master.jpg',
    nameProductEa: 'Dalmatian puppy',
    recommendation: [
      {
        id: '3006',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-puppy-3006',
        nameProductRu: 'Макси Паппи: сухой корм для щенков в возрасте до 15 месяцев',
        text: 'корм для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/AVaLTIABBKJuub5qg90J/v7/maxi-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'Maxi Puppy',
        priority: 1,
      },
      {
        id: '3030',
        linkProduct: 'https://www.royalcanin.com/ru/shop/giant-puppy-3030',
        nameProductRu: 'Джайнт Паппи',
        text: 'сухой корм для щенков в возрасте до 8 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/4FbPRoABBKJuub5qE9yr/v15/giant-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'GIANT Puppy',
        priority: 2,
      }
    ]
  },
  {
    id: '2598',
    linkProduct: 'https://www.royalcanin.com/ru/shop/dalmatian-2598',
    text: 'сухой корм для взрослых собак с 15-месячного возраста.',
    nameProductRu: 'Далматин',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137050-master.jpg',
    nameProductEa: 'Dalmatian Adult ',
    recommendation: [
      {
        id: '3007',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-adult-3007',
        nameProductRu: 'Макси Эдалт: сухой корм для собак в возрасте с 15 месяцев до 5 лет',
        text: 'корм для собак с чевствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137067-master.jpg',
        nameProductEa: 'Maxi Adult',
        priority: 1,
      },
      {
        id: '3008',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-adult-5+-3008',
        nameProductRu: 'Макси эдалт 5+',
        text: 'сухой корм для собак в возрасте с 5 до 8 лет',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137064-master.jpg',
        nameProductEa: 'Maxi Adult 5+',
        priority: 1,
      },
      {
        id: '2454',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-ageing-8+-2454',
        nameProductRu: 'Макси эйджинг 8+',
        text: 'сухой корм для стареющих собак в возрасте 8 лет и старше.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137070-master.jpg',
        nameProductEa: 'Maxi Ageing 8+',
        priority: 1,
      },
      {
        id: '2444',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-dermacomfort-2444',
        nameProductRu: 'Макси дерма комфорт',
        text: 'сухой корм рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью кожи. ',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-178312-master.jpg',
        nameProductEa: 'Maxi Dermacomfort',
        priority: 2,
      },
      {
        id: '3055',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-digestive-care-3055',
        nameProductRu: 'Макси дайджестив кэа',
        text: 'корм сухой для собак с чувствительной пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263243-master.jpg',
        nameProductEa: 'Maxi Digestive Care',
        priority: 2,
      }
    ]
  },
  {
    id: '3967',
    linkProduct: 'https://www.royalcanin.com/ru/shop/bulldog-puppy-3967',
    text: 'для щенков в возрасте до 12 месяцев',
    nameProductRu: 'Бульдог Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_662203_master.jpg',
    nameProductEa: 'Bulldog Puppy',
    recommendation: [
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 2,
      },
      {
        id: '3003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-puppy-3003',
        nameProductRu: 'Медиум Паппи',
        text: 'сухой корм для щенков в возрасте до 12 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/WlaIZYABBKJuub5q-91u/v7/medium-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'Medium Puppy',
        priority: 1,
      },
    ]
  },
  {
    id: '3979',
    linkProduct: 'https://www.royalcanin.com/ru/shop/golden-retriever-puppy-3979',
    text: 'для щенков в возрасте до 15 месяцев',
    nameProductRu: 'Золотистый (голден) ретривер Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_661452_master.jpg',
    nameProductEa: 'Golden Retriever Puppy',
    recommendation: [
      {
        id: '3006',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-puppy-3006',
        nameProductRu: 'Макси Паппи: сухой корм для щенков в возрасте до 15 месяцев',
        text: 'корм для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/AVaLTIABBKJuub5qg90J/v7/maxi-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'Maxi Puppy',
        priority: 1,
      },
      {
        id: '3030',
        linkProduct: 'https://www.royalcanin.com/ru/shop/giant-puppy-3030',
        nameProductRu: 'Джайнт Паппи',
        text: 'сухой корм для щенков в возрасте до 8 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/4FbPRoABBKJuub5qE9yr/v15/giant-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'GIANT Puppy',
        priority: 2,
      }
    ]
  },
  {
    id: '3968',
    linkProduct: 'https://www.royalcanin.com/ru/shop/cavalier-king-charles-adult-3968',
    text: 'для взрослых собак в возрасте от 10 месяцев и старше',
    nameProductRu: 'Кавалер Кинг Чарльз Спаниель',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/HCI6a2QBaxEApS7L2wOF/v188/ad-cavalier-packshot-bhn18',
    nameProductEa: 'Cavalier King Charles Adult',
    recommendation: [
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2441',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dermacomfort-2441',
        nameProductRu: 'Мини дермакомфорт',
        text: 'cухой корм для собак - рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263924-master.jpg',
        nameProductEa: 'Mini Dermacomfort',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      }
    ]
  },
  {
    id: '4132',
    linkProduct: 'https://www.royalcanin.com/ru/shop/cavalier-king-charles-puppy-4132',
    text: 'для щенков в возрасте до 10 месяцев',
    nameProductRu: 'Кавалер Кинг Чарльз Спаниель Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_661433_master.jpg',
    nameProductEa: 'Cavalier King Charles Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '4133',
    linkProduct: 'https://www.royalcanin.com/ru/shop/cocker-puppy-4133',
    text: 'для щенков в возрасте до 12 месяцев',
    nameProductRu: 'Кокер Спаниель Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_661378_master.jpg',
    nameProductEa: 'Cocker Puppy',
    recommendation: [
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 2,
      },
      {
        id: '3003',
        linkProduct: 'https://www.royalcanin.com/ru/shop/medium-puppy-3003',
        nameProductRu: 'Медиум Паппи',
        text: 'сухой корм для щенков в возрасте до 12 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/WlaIZYABBKJuub5q-91u/v7/medium-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'Medium Puppy',
        priority: 1,
      },
    ]
  },
  {
    id: '3057',
    linkProduct: 'https://www.royalcanin.com/ru/shop/poodle-adult-3057',
    text: 'для взрослых собак в возрасте от 10 месяцев и старше',
    nameProductRu: 'Пудель',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_271047_master.jpg',
    nameProductEa: 'Poodle Adult',
    recommendation: [
      {
        id: '3001',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3001',
        nameProductRu: 'Мини Эдалт',
        text: 'сухой корм для собак в возрасте c 10 месяцев до 8 лет.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137076-master.jpg',
        nameProductEa: 'Mini Adult',
        priority: 1,
      },
      {
        id: '2434',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-indoor-adult-2434',
        nameProductRu: 'Мини Индор Эдалт',
        text: 'для собак в возрасте от 10 месяцев и старше, живущих главным образом в помещении',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-137080-master.jpg',
        nameProductEa: 'Mini Indoor Adult',
        priority: 1,
      },
      {
        id: '2441',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dermacomfort-2441',
        nameProductRu: 'Мини дермакомфорт',
        text: 'cухой корм для собак - рекомендуется при раздражениях и зуде, связанных с повышенной чувствительностью',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-263924-master.jpg',
        nameProductEa: 'Mini Dermacomfort',
        priority: 2,
      },
      {
        id: '1221',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-dental-care-1221',
        nameProductRu: 'Мини Дентал Кэа',
        text: 'корм для собак с повышенной чувствительностью зубов.',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-264327-master.jpg',
        nameProductEa: 'Mini Dental Care',
        priority: 2,
      },
      {
        id: '2447',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-digestive-care-2447',
        nameProductRu: 'Мини дайджестив кэа',
        text: 'корм сухой для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-261053-master.jpg',
        nameProductEa: 'Mini Digestive Care',
        priority: 2,
      },
      {
        id: '3185',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-sterilised-3185',
        nameProductRu: 'Мини стерилайзд',
        text: 'корм для стерилизованных собак',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/ruv1/ru-275967-master.jpg',
        nameProductEa: 'Mini Sterilised',
        priority: 2,
      }
    ]
  },
  {
    id: '3080',
    linkProduct: 'https://www.royalcanin.com/ru/shop/poodle-puppy-3080',
    text: 'для щенков в возрасте до 10 месяцев',
    nameProductRu: 'Пудель Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_662255_master.jpg',
    nameProductEa: 'Poodle Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '4131',
    linkProduct: 'https://www.royalcanin.com/ru/shop/schnauzer-puppy-4131',
    text: 'для щенков в возрасте до 10 месяцев',
    nameProductRu: 'Миниатюрный Шнауцер Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_661436_master.jpg',
    nameProductEa: 'Schnauzer Puppy',
    recommendation: [
      {
        id: '1002',
        linkProduct: 'https://www.royalcanin.com/ru/shop/x-small-puppy-1002',
        nameProductRu: 'Икс-смол Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/vz8wZoABRYZmsWpct9lM/v7/sol-x-small-puppy-dry-packshot-b1-editable-version-794347-rus',
        nameProductEa: 'X-Small Puppy',
        priority: 2,
      },
      {
        id: '3000',
        linkProduct: 'https://www.royalcanin.com/ru/shop/mini-puppy-3000',
        nameProductRu: 'Мини Паппи',
        text: 'сухой корм для щенков в возрасте до 10 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/KecW1n8BaPOZra8qZNZs/v7/microsoftteams-image-2',
        nameProductEa: 'Mini Puppy',
        priority: 1,
      }
    ]
  },
  {
    id: '3966',
    linkProduct: 'https://www.royalcanin.com/ru/shop/boxer-puppy-3966',
    text: 'для щенков в возрасте до 15 месяцев',
    nameProductRu: 'Боксер Паппи',
    image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://d2cfgseuprod.z6.web.core.windows.net/RU_662250_master.jpg',
    nameProductEa: 'Boxer Puppy',
    recommendation: [
      {
        id: '3006',
        linkProduct: 'https://www.royalcanin.com/ru/shop/maxi-puppy-3006',
        nameProductRu: 'Макси Паппи: сухой корм для щенков в возрасте до 15 месяцев',
        text: 'корм для собак с чувствительным пищеварением',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/AVaLTIABBKJuub5qg90J/v7/maxi-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'Maxi Puppy',
        priority: 1,
      },
      {
        id: '3030',
        linkProduct: 'https://www.royalcanin.com/ru/shop/giant-puppy-3030',
        nameProductRu: 'Джайнт Паппи',
        text: 'сухой корм для щенков в возрасте до 8 месяцев',
        image: 'https://d2c-cdn.royalcanin.com/cdn-cgi/image/width=500,h=500,format=webp/https://cdn.royalcanin-weshare-online.io/4FbPRoABBKJuub5qE9yr/v15/giant-puppy-packshot-profil-b1-sol21-rus',
        nameProductEa: 'GIANT Puppy',
        priority: 2,
      }
    ]
  }
]
