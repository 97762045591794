import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import './index.scss';
import {App} from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {store} from "./app/store";
import {Provider} from "react-redux";

const tagManagerArgs = {
  gtmId: 'GTM-MRD7FXV'
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize('UA-176139745-2', { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter basename="/alternative">
    <Provider store={store}>
      <App/>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
